<script>
import axios from 'axios'

export default {
    scopeName: 'wishlist',
    mounted() {
        const qtyFormInputs = document.querySelectorAll('[data-qtyForm-input]')
        qtyFormInputs.forEach((i) => {
            i.addEventListener(
                'qty-change',
                () => {
                    this.UpdateProductQty(i)
                },
                false
            )
        })
    },
    methods: {
        UpdateProductQty(input) {
            const form = input.form

            const url = document.querySelector('[data-url-update-qty]').dataset
                .urlUpdateQty

            const wishlist_id = document.querySelector('[data-wishlist-id]')
                .dataset.wishlistId

            const data = {
                wishlist_id: Number(wishlist_id),
                form_key: form['form_key'].value,
                [`qty[${form['wishlistItem'].value}]`]: input.value
            }

            const formData = new FormData()

            for (let key in data) {
                formData.append(key, data[key])
            }

            axios({
                method: 'post',
                url,
                data: formData
            })
        },
        AddAllItemsToCart(trigger) {
            const formsContainer = document.getElementById(
                'wishlist-item-forms'
            )
            const forms = formsContainer.querySelectorAll(
                'form.product_addtocart_form'
            )
            window.$apteline.cart.AddToCart(trigger, { forms, redirect: true })
        },
        AddSelectedItemsToCart(trigger) {
            const checkedTrs = Array.from(
                document.querySelectorAll('input:checked')
            )
            const forms = checkedTrs.map((el) => el.form)
            window.$apteline.cart.AddToCart(trigger, { forms, redirect: true })
        }
    },
    render() {
        return null
    }
}
</script>
